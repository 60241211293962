import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ServicePost2 from "./service-post2";
// import ServiceSidebar from "./service-sidebar";

const ServiceDetails2 = () => {
  return (
    <section className="service_details_area section_padding">
      <Container>
        <Row>
          <Col lg={12}>
            <ServicePost2 />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ServiceDetails2;
