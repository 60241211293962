import React from "react";
import servicedetails2 from "@/images/services/service-detail2.jpg";
import Img from "./img";

const ServicePost2 = () => {
  return (
    <div className="service_details_left">
      <Img
        src={servicedetails2}
        alt="Service Details Image"
        title="Service Details Image"
      />
      <h2 style={{ fontSize: "40px" }}>Cockroach Extermination</h2>
      <p>
        Cockroaches are almost everywhere. Starting from your kitchen sinks and
        bathroom to your living space, cockroaches irritate our living
        significantly. Cockroach-destroying sprays, which are available in the
        market, can reduce their numbers minimally. But, for a more extensive
        solution, you've to turn your head for help to the professional pest
        control agencies. They are also indiscriminating in terms of living
        conditions, indicating their easy adjustment capability to survive in
        any atmosphere. Besides, cockroaches can boost their population in a
        brisk, making them even harder to control. So, for a lasting solution to
        cockroach infestation issues in your home, Nifixeci is the ideal choice
        for you.
      </p>
      <h3>Problems with Cockroaches</h3>
      <li>
        They can easily bring bacteria and dangerous pathogens to disperse them
        indoors quickly.
      </li>
      <li>
        Cockroaches are also quite popular to contaminate your food and
        different surfaces.
      </li>
      <li>
        They also work as a transporter of allergies and can cause asthma, which
        can become deadly.
      </li>
      <li>
        Cockroaches also spread some odd odors throughout the place where they
        are reproducing.
      </li>
      <li>
        They have a fast-paced breeding cycle, and it becomes tougher to control
        their numbers.
      </li>
      <li>
        It’s almost not effectively feasible to terminate them through DIY
        approaches.
      </li>

      <br />
      <h3>Way to Exterminate Them</h3>
      <li>
        One of the prominent methods that our technicians use is spraying
        pesticides on filthy surfaces. Whenever they come in contact with the
        pesticide, they die.
      </li>
      <li>
        Another approach that we employ is poisoning cockroaches' stomachs with
        baits in places where their existence is prominent.
      </li>
      <li>
        The final set of solutions Nifixeci deploys is sustainable approaches to
        establish plagues to destroy all cockroaches.
      </li>
    </div>
  );
};

export default ServicePost2;
