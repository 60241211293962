import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import PageHeader from "@/components/page-header";
import Footer from "@/components/footer";
import ContactTwo from "@/components/contact-two";
import ServiceDetails2 from "@/components/service-details2";
import { Helmet as Head } from "react-helmet";

const ServiceDetailsPage2 = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="Service Details">
        <Head>
          <title>Cockroach Extermination | Nifixeci</title>
          <meta
            name="description"
            content="Get your homes and offices cockroach-free with our three-step
            eco-friendly solutions."
          />
        </Head>
        <HeaderOne />
        <PageHeader title="Cockroach Extermination" name="Service" />
        <ServiceDetails2 />
        {/* <ContactTwo /> */}
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default ServiceDetailsPage2;
